import * as React from 'react';
import { useDiceStyle } from '../../redux/settings/selectors';
import { useAppDispatch } from '../../redux/store';
import { ItemSelector } from '../common/ItemSelector/ItemSelector';
import { Stack, StackDirection } from '../common/Stack';
import { Constants } from '../../constants';
import { DiceStyle } from '../../redux/state';
import { getEnumValues } from '../../utils/enumUtils';
import styles from './DiceStyleSelector.module.css';
import { SettingsThunk } from '../../redux/settings/thunks';

type Props = {
  /* */
};

export const DiceSkinSelector: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const currentDiceStyle = useDiceStyle();

  return (
    <div className={styles.container}>
      <Stack direction={StackDirection.Vertical} gap={10}>
        <div className={styles.title}>Dice Style</div>
        <ItemSelector
          items={getEnumValues(DiceStyle)}
          currentItem={currentDiceStyle}
          selectorTitle="Select Dice Style"
          displayAs="list"
          panelPlacement="right"
          fullWidth={true}
          onItemChanged={(selectedItem) => dispatch(SettingsThunk.setDiceStyle(selectedItem))}
          renderItem={(diceStyle) => <div className={styles.item}>{getDiceStyleName(diceStyle)}</div>}
        />
      </Stack>
    </div>
  );
};

const getDiceStyleName = (diceStyle: DiceStyle | undefined): string => {
  if (diceStyle) {
    const info = Constants.DiceStyleInfos.get(diceStyle);

    if (info) {
      return info.displayName;
    }
  }

  return 'Default';
};
