import React from 'react';
import { Panel, PanelPlacement } from '../common/Panel/Panel';
import { DiceSelector } from '../DiceSelector/DiceSelector';
import { ResultModifier } from '../ResultModifier/ResultModifier';
import { RollSuggestions } from '../RollSuggestions/RollSuggestions';
import { DiceSkinSelector } from '../DiceStyleSelector/DiceStyleSelector';
import { Stack, StackDirection } from '../common/Stack';

import styles from './DicePanel.module.css';

type Props = {
  onDismiss: () => void;
  panelPlacement?: PanelPlacement;
};

export const DicePanel: React.FC<Props> = ({ panelPlacement, onDismiss }) => {
  return (
    <Panel onDismiss={onDismiss} panelPlacement={panelPlacement} showOverlay={panelPlacement === 'bottom'}>
      <Stack direction={StackDirection.Vertical} gap={20} className={styles.container}>
        <RollSuggestions />
        <DiceSelector />
        <ResultModifier />
        <DiceSkinSelector />
      </Stack>
    </Panel>
  );
};
