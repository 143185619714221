import * as Immutable from 'immutable';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnalyticsApi } from '../api/Analytics/analytics';
import { AbilityType } from '../api/DnD/abilities';
import { HeroClass } from '../api/DnD/classes';
import { DiceType } from '../api/DnD/dices';
import { Race } from '../api/DnD/races';
import { CardsStorage } from '../api/cardsStorage';
import { HeroStorage } from '../api/heroStorage';
import { SettingsStorage } from '../api/settingsStorage';
import { CardActionType } from './cards/reducer';
import { CharacterActionType } from './character/reducer';
import { DiceActionType } from './dices/reducer';
import { PanelsActionType } from './panels/reducer';
import { PanelsState } from './panels/types';
import { SettingsActionType } from './settings/reducer';

//TODO: maybe we can replace this with Action<string, unknown>
export type AppActions = DiceActionType | SettingsActionType | CharacterActionType | CardActionType | PanelsActionType;

export type AppDispatch = ThunkDispatch<AppState, MutableThunkState, AppActions>;
export type AppThunkAction = ThunkAction<void | Promise<void>, AppState, MutableThunkState, AppActions>;

export type MutableThunkState = {
  heroStorage: HeroStorage;
  settingsStorage: SettingsStorage;
  analytics: AnalyticsApi;
  cardsStorage: CardsStorage;
};

export type RollResult = {
  diceType: DiceType;
  value: number;
};

export type RollResults = {
  results: Immutable.List<RollResult>;
  withAbility?: {
    type: AbilityType;
    modifier: number;
  };
  withResultModifier?: number | undefined;
  withRollType: RollType;
};

export type CalculatedRollResults = RollResults & { finalResult: number };

/**
 * slingshot - user drags dice(s), arrow is shown and dice(s) fly to the arrow direction
 * tap - user tapped on the scree, dice(s) drop on the tap place
 * swipe - user quickly swipes on the scree, dice(s) fly toward the swipe direction
 * init - happens automatically when game is loadded or when dice count has changed from the dice panel
 */
export type RollSource = 'slingshot' | 'tap' | 'swipe' | 'init';

export type RollSuggetion = {
  dices: string[]; //NOTE: array of dices in format ["2d20", "1d4"]
  resultModifier: number;
  rollType: RollType;
};

export enum DiceStyle {
  Solid = 'Solid',
  Original = 'Original',
}

export type SettingsState = {
  readonly showAvatar: boolean;
  readonly rollSuggestions?: RollSuggetion[];
  readonly characterToImport?: CharacterState;
  readonly currentTag?: string;
  readonly orthographicCameraEnabled: boolean;
  readonly alterntaeArrow: boolean;
  readonly diceStyle: DiceStyle;
};

export type CharacterState = {
  readonly abilities: Immutable.Map<AbilityType, number>;
  readonly characterInfo: CharacterInfo;
};

export type CharacterInfo = {
  readonly name: string;
  readonly race: Race;
  readonly class: HeroClass;
  readonly avatarFileName: string;
  readonly level: number;
};

export type RollType = 'normal' | 'advantage' | 'disadvantage';

export type DicesState = {
  readonly rollInProgress: boolean;
  readonly dicesToRoll: Immutable.Map<DiceType, number>;
  readonly rollResults: RollResults | undefined;
  readonly rollModifier: AbilityType | undefined;
  /**
   * If specified will be added to the roll results.
   */
  readonly resultModifier: number | undefined;
  readonly resetAbilityOnNextRoll?: boolean;
  readonly rollType: RollType;
};

export type CardsState = {
  /**
   * All Inventory items.
   */
  readonly cards: Immutable.List<CardInfo>;

  /**
   * Currently selected item in the Invantory.
   */
  readonly selectedCard?: CardInfo;

  /**
   * Type of cards that will be shown in the inventory.
   */
  readonly itemTypeFilter?: string | undefined;
};

export type CardInfo = {
  id: string;
  type?: string;
  title: string;
  description: string;
  image?: string;
  classes?: HeroClass[];
  races?: Race[];
  tags?: string[];
  charnames?: string[];
};

export type AppState = {
  dices: DicesState;
  character: CharacterState;
  settings: SettingsState;
  cards: CardsState;
  panels: PanelsState;
};
