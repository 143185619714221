import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { FACE_TEXTS } from '../r3fConstants';
import { createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const desc: DiceDescription = {
    type: DiceType.d8,
    tab: 0,
    af: -Math.PI / 4 / 2,
    // chamfer: 0.965,//NOTE: 1 - нет кромки, 0.965 - небольшая кромка,  0 - самая большая кромка
    chamfer: 1,
    vertices: [
      [1, 0, 0],
      [-1, 0, 0],
      [0, 1, 0],
      [0, -1, 0],
      [0, 0, 1],
      [0, 0, -1],
    ],
    faces: [
      [1, 4, 2, 1],
      [0, 3, 5, 2],
      [0, 4, 3, 3],
      [1, 2, 5, 4],
      [0, 5, 2, 5],
      [1, 3, 4, 6],
      [1, 5, 3, 7],
      [0, 2, 4, 8],
    ],
    scaleFactor: 1,
    values: 8,
    faceTexts: FACE_TEXTS,
    textMargin: 1.2,
    mass: 340,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#010e04',
  fontColor: '#ffffff',
};

export const D8: DiceInfo = {
  description,
  options,
  geometry: createGeometry(options.size, description),
  args: createShape(description, options.size),
};
