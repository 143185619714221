import * as THREE from 'three';

import { Triplet } from '@react-three/cannon';
import { Camera, useThree, Viewport } from '@react-three/fiber';
import React from 'react';
import { FrontSide } from 'three';
import { DiceThunk } from '../../redux/dices/thunks';
import { useAppDispatch } from '../../redux/store';
import { ContactMaterials } from './ContactMaterials';
import { Dices } from './Dices';
import { DragInfo } from './diceTypes';
import { Lights } from './Lights';
import { BodyNames } from './r3fConstants';
import { Wall } from './Wall';

const CEILING_HEIGHT = 100;
const THROW_HEIGHT = 20;

type Props = {
  dragInfo: DragInfo | undefined;
  onDicesRolled: () => void;
};

export const DiceScene: React.FC<Props> = ({ dragInfo, onDicesRolled }): JSX.Element => {
  const dispatch = useAppDispatch();
  const viewport = useThree(({ viewport }) => viewport);
  const camera = useThree(({ camera }) => camera);

  //TODO: that is not wery optimal. Better to create dragInfo with 3D points, because after this call we do not use 2D points anymore
  const newDragInfo: DragInfo | undefined = dragInfo
    ? {
        ...dragInfo,
        velocity: dragInfo.velocity && divideToScalar(dragInfo.velocity, viewport.factor * camera.zoom),
        position3D: toPosition3D(dragInfo.position2D, THROW_HEIGHT, viewport, camera),
        startPosition3D: toPosition3D(dragInfo.startPosition2D, THROW_HEIGHT, viewport, camera),
      }
    : undefined;

  // NOTE: To export dice models, uncomment this and <group below
  // const groupRef = useRef<THREE.Group>(null);
  // const exporter = useObjExporter('dices.obj');

  // if (groupRef.current) {
  //   exporter(groupRef.current);
  // }

  return (
    <>
      <ContactMaterials />
      <Lights />

      {/* <Html>
        <div>{safeAreaBottom}</div>
        <div>{viewport.factor}</div>
      </Html> */}

      {/* Walls */}
      <Wall side="floor" distanceFromCenter={0} receiveShadow={true} name={BodyNames.Floor}>
        <shadowMaterial opacity={0.4} side={FrontSide} shadowSide={FrontSide} />
        <planeGeometry args={[viewport.width, viewport.height]} />
      </Wall>
      <Wall side="ceil" distanceFromCenter={CEILING_HEIGHT} name={BodyNames.Wall} />
      <Wall side="near" distanceFromCenter={viewport.height / 2} name={BodyNames.Wall} />
      <Wall side="far" distanceFromCenter={(-1 * viewport.height) / 2} name={BodyNames.Wall} />
      <Wall side="left" distanceFromCenter={(-1 * viewport.width) / 2} name={BodyNames.Wall} />
      <Wall side="right" distanceFromCenter={viewport.width / 2} name={BodyNames.Wall} />

      {/* Dices */}
      {/* <group ref={groupRef} onClick={() => groupRef.current && exporter(groupRef.current)}> */}
      <Dices
        dragInfo={newDragInfo}
        onDicesGrabbed={() => dispatch(DiceThunk.startDiceRoll())}
        onDicesRolled={onDicesRolled /* Physicks kicks off, we are not dragging anymore */}
        onRollCompleted={(rollResults, rollSource) => dispatch(DiceThunk.completeDiceRoll(rollResults, rollSource))}
      />
      {/* </group> */}
    </>
  );
};

type Point = [x: number, y: number];

const toPosition3D = (point: Point, throwHight: number, viewport: Viewport, camera: Camera): Triplet => {
  const x = point[0] / (viewport.factor * camera.zoom);
  const y = point[1] / (viewport.factor * camera.zoom);

  if (camera.type === 'PerspectiveCamera') {
    const worldPoint = getSceneToWorld(x, y, camera, viewport, throwHight);
    return worldPoint;
  }

  return [x - viewport.width / 2, throwHight, y - viewport.height / 2];
};

const multiplyToScalar = (point: Point, scalar: number): Point => point.map((c) => c * scalar) as Point;
const divideToScalar = (point: Point, scalar: number): Point => point.map((c) => c / scalar) as Point;

//https://stackoverflow.com/a/34668332
//https://stackoverflow.com/questions/59691505/three-js-map-2d-to-3d-using-orthogonal-camera
//https://discourse.threejs.org/t/failed-to-unproject-vector-using-orthogonal-camera/28102
const getSceneToWorld = (
  dx: number,
  dy: number,
  camera: Camera,
  viewport: Viewport,
  distanceFromFloor: number,
): Triplet => {
  //To NDC space
  const mouse3D = new THREE.Vector3((dx / viewport.width) * 2 - 1, (-dy / viewport.height) * 2 + 1, 0.5);

  mouse3D.unproject(camera);
  mouse3D.sub(camera.position);
  mouse3D.normalize();

  const distance = -(camera.position.y - distanceFromFloor) / mouse3D.y;
  const pos = camera.position.clone().add(mouse3D.multiplyScalar(distance));
  return pos.toArray();
};
