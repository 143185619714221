import { useLoader } from '@react-three/fiber';
import { useMemo } from 'react';
import { BufferGeometry, Mesh } from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

//READ: https://medium.com/@matthewmain/how-to-import-a-3d-blender-object-into-a-three-js-project-as-a-gltf-file-5a67290f65f2

// https://stackoverflow.com/questions/68725185/react-three-fiber-add-texture-to-obj-model
export const useObjModel = (filename: string): BufferGeometry | undefined => {
  const objModel = useLoader(OBJLoader, filename);

  const geometry = useMemo<BufferGeometry | undefined>(() => {
    let meshGeometry: BufferGeometry | undefined = undefined;

    objModel.traverse((child) => {
      if (child.type === 'Mesh') {
        const mesh = child as Mesh;
        meshGeometry = mesh.geometry;
      }
    });

    return meshGeometry;
  }, [objModel]);

  return geometry;
};
