import { DiceStyle, RollSuggetion } from '../redux/state';
import { toEnumValue } from '../utils/enumUtils';

const SHOW_AVATAR_KEY = 'show_avatar';
const GAME_TAG_KEY = 'game_tag';
const ROLL_SUGGESTIONS_KEY = 'roll_suggestions';
const ORTHOGRAPHIC_CAMERA_KEY = 'enable_orthographic_camera';
const ALTERNTAE_ARROW_KEY = 'alterntae_arrow';
const DICE_STYLE_KEY = 'dice_style';

// Avatar
const getShowAvatar = (): boolean => {
  const value = localStorage.getItem(SHOW_AVATAR_KEY);
  return value === undefined || value === null || value === true.toString();
};

const setShowAvatar = (show: boolean): void => localStorage.setItem(SHOW_AVATAR_KEY, JSON.stringify(show));

// Game Tag
const getGameTag = (): string | undefined => {
  const tag = localStorage.getItem(GAME_TAG_KEY);
  return tag || undefined;
};

const setGameTag = (tag?: string): void => localStorage.setItem(GAME_TAG_KEY, tag || '');

// Roll Suggestions
const saveRollSuggestions = (rollSuggestions: RollSuggetion[]): void => {
  localStorage.setItem(ROLL_SUGGESTIONS_KEY, JSON.stringify(rollSuggestions));
};

const getRollSuggestions = (): RollSuggetion[] | undefined => {
  const valuesToParse = localStorage.getItem(ROLL_SUGGESTIONS_KEY);

  if (valuesToParse) {
    const rollSuggestions = JSON.parse(valuesToParse) as RollSuggetion[];

    if (Array.isArray(rollSuggestions)) {
      return rollSuggestions;
    }
  }

  return undefined;
};

// Orthographic Camera
const getEnableOrthographicCamera = (): boolean => localStorage.getItem(ORTHOGRAPHIC_CAMERA_KEY) === true.toString();

const setEnableOrthographicCamera = (enable: boolean): void =>
  localStorage.setItem(ORTHOGRAPHIC_CAMERA_KEY, JSON.stringify(enable));

// Alterntae Arrow
const getAlterntaeArrow = (): boolean => localStorage.getItem(ALTERNTAE_ARROW_KEY) === true.toString();

const setAlterntaeArrow = (enable: boolean): void => localStorage.setItem(ALTERNTAE_ARROW_KEY, JSON.stringify(enable));

// Dice Style
const getDiceStyle = (): DiceStyle => {
  const value = localStorage.getItem(DICE_STYLE_KEY);
  const result = toEnumValue(DiceStyle, value ?? '', DiceStyle.Original);
  return result;
};

const setDiceStyle = (diceStyle: DiceStyle): void => {
  localStorage.setItem(DICE_STYLE_KEY, diceStyle);
};

export type SettingsStorage = {
  getShowAvatar: () => boolean;
  setShowAvatar: (show: boolean) => void;
  getGameTag: () => string | undefined;
  setGameTag: (tag?: string) => void;
  saveRollSuggestions: (rollSuggestions: RollSuggetion[]) => void;
  getRollSuggestions: () => RollSuggetion[] | undefined;
  getEnableOrthographicCamera: () => boolean;
  setEnableOrthographicCamera: (enable: boolean) => void;
  getAlterntaeArrow: () => boolean;
  setAlterntaeArrow: (enable: boolean) => void;
  getDiceStyle: () => DiceStyle;
  setDiceStyle: (diceStyle: DiceStyle) => void;
};

export const createSettingsStorage = (): SettingsStorage => ({
  getShowAvatar,
  setShowAvatar,
  getGameTag,
  setGameTag,
  saveRollSuggestions,
  getRollSuggestions,
  getEnableOrthographicCamera,
  setEnableOrthographicCamera,
  getAlterntaeArrow,
  setAlterntaeArrow,
  getDiceStyle,
  setDiceStyle,
});
