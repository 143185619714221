import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { FACE_TEXTS } from '../r3fConstants';
import { createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const desc: DiceDescription = {
    type: DiceType.d6,
    tab: 0.1,
    af: Math.PI / 4,
    // chamfer: 0.96,
    chamfer: 1,
    vertices: [
      [-1, -1, -1],
      [1, -1, -1],
      [1, 1, -1],
      [-1, 1, -1],
      [-1, -1, 1],
      [1, -1, 1],
      [1, 1, 1],
      [-1, 1, 1],
    ],
    faces: [
      [3, 7, 6, 2, 1],
      [4, 5, 6, 7, 2],
      [0, 4, 7, 3, 3],
      [1, 2, 6, 5, 4],
      [0, 3, 2, 1, 5],
      [0, 1, 5, 4, 6],
    ],
    scaleFactor: 0.9,
    values: 6,
    faceTexts: FACE_TEXTS,
    textMargin: 1.0,
    mass: 300,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#010e04',
  fontColor: '#ffffff',
};

export const D6: DiceInfo = {
  description,
  options,
  geometry: createGeometry(options.size, description),
  args: createShape(description, options.size),
};
