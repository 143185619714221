export const getEnumValues = <T>(enumType: { [s: string]: T } | ArrayLike<T>): T[] => {
  return Object.values(enumType);
};

export const toEnumValue = <T>(
  enumType: { [s: string]: T } | ArrayLike<T>,
  rawValue: string | number | undefined,
  defaultValue: T,
): T => {
  return Object.values(enumType).find((type) => type === rawValue) || defaultValue;
};
