import { Instances, useTexture } from '@react-three/drei';
import React, { useMemo } from 'react';
import { FrontSide } from 'three';
import { DiceType } from '../../api/DnD/dices';
import { Constants } from '../../constants';
import { useObjModel } from '../../hooks/useObjModel';
import { useDiceStyle } from '../../redux/settings/selectors';
import { DiceFrameCallback, DiceInstance, DiceStartCallback, DiceStopCallback } from './DiceInstance';
import { D10 } from './diceData/Dice_D10';
import { D12 } from './diceData/Dice_D12';
import { D20 } from './diceData/Dice_D20';
import { D4 } from './diceData/Dice_D4';
import { D6 } from './diceData/Dice_D6';
import { D8 } from './diceData/Dice_D8';
import { DiceInfo } from './diceTypes';
import { BodyNames } from './r3fConstants';
import { DiceSounds } from './sounds';
import { createDiceTextures } from './utils';

const MAX_DICE_INSTANCES = 5;

type Props = {
  diceType: DiceType;
  count: number;
  limit?: number;
  range?: number;
  diceSounds: DiceSounds | undefined;
  onFrame: DiceFrameCallback;
  onStarted: DiceStartCallback;
  onStopped: DiceStopCallback;
};

//NOTE: https://github.com/pmndrs/drei#instances
export const DiceInstances: React.FC<Props> = ({
  diceType,
  count,
  limit = MAX_DICE_INSTANCES,
  range = MAX_DICE_INSTANCES,
  diceSounds,
  onFrame,
  onStarted,
  onStopped,
}) => {
  //NOTE: won't cause re-render in other components, DiceInfo never changes, it is already cached on the start of the app
  const diceStyle = useDiceStyle();
  const diceStyleInfo = Constants.DiceStyleInfos.get(diceStyle);
  const isGenerated = diceStyleInfo?.styleName === undefined;
  const children = createDiceInstances(diceType, count, diceSounds, onFrame, onStarted, onStopped);

  //NOTE: https://threejs.org/docs/#api/en/materials/MeshPhongMaterial
  return isGenerated ? (
    <GeneratedInstances diceType={diceType} limit={limit} range={range}>
      {children}
    </GeneratedInstances>
  ) : (
    <ObjModelInstances diceType={diceType} diceStyleName={diceStyleInfo.styleName!} limit={limit} range={range}>
      {children}
    </ObjModelInstances>
  );
};

type InstancesProps = {
  diceType: DiceType;
  limit?: number;
  range?: number;
  children?: React.ReactNode;
};

const ObjModelInstances: React.FC<InstancesProps & { diceStyleName: string }> = ({
  diceType,
  diceStyleName,
  limit,
  range,
  children,
}) => {
  //NOTE: won't cause re-render in other components, DiceInfo never changes, it is already cached on the start of the app
  const modelPath = `/dices/${diceType}.obj`;
  const texturePath = `/dices/styles/${diceStyleName}/${diceType}.png`;

  const geometry = useObjModel(modelPath);
  const texture = useTexture(texturePath);

  // зависает если сделать Clear Site Data и потом выбрать Stylized

  if (!geometry) {
    throw new Error(`Missing .obj file for ${diceType}`);
  }

  if (!texture) {
    throw new Error(`Missing .png texture for ${diceType}`);
  }

  //NOTE: https://threejs.org/docs/#api/en/materials/MeshPhongMaterial
  return (
    <Instances
      limit={limit} // Optional: max amount of items (for calculating buffer size)
      range={range} // Optional: draw-range
      receiveShadow={true}
      castShadow={true}
      geometry={geometry}
      name={BodyNames.Dice}>
      <meshPhongMaterial side={FrontSide} specular={0x33474d} shininess={20} flatShading={true} map={texture} />
      {children}
    </Instances>
  );
};

const GeneratedInstances: React.FC<InstancesProps> = ({ diceType, limit, range, children }) => {
  //NOTE: won't cause re-render in other components, DiceInfo never changes, it is already cached on the start of the app
  const dice = toDiceInfo(diceType);
  const textures = useMemo(() => createDiceTextures(dice.description, dice.options), [dice]);

  //NOTE: https://threejs.org/docs/#api/en/materials/MeshPhongMaterial
  return (
    <Instances
      limit={limit} // Optional: max amount of items (for calculating buffer size)
      range={range} // Optional: draw-range
      receiveShadow={true}
      castShadow={true}
      geometry={dice.geometry}
      name={BodyNames.Dice}>
      {textures.map((texture, index) => (
        <meshPhongMaterial
          key={index}
          attach={`material-${index}`} //without this, all faces will have the same texture (same number will be shown)
          side={FrontSide}
          specular={0x33474d}
          shininess={20}
          flatShading={true}
          map={texture}
        />
      ))}
      {children}
    </Instances>
  );
};

const createDiceInstances = (
  diceType: DiceType,
  count: number,
  diceSounds: DiceSounds | undefined,
  onFrame: DiceFrameCallback,
  onStarted: DiceStartCallback,
  onStopped: DiceStopCallback,
) => {
  const diceElements: JSX.Element[] = [];
  const diceInfo = toDiceInfo(diceType);

  for (let i = 0; i < count; i++) {
    diceElements.push(
      <DiceInstance
        key={`${diceInfo.description.type}-${i}`}
        instanceIndex={i}
        diceInfo={diceInfo}
        diceSounds={diceSounds}
        onFrame={onFrame}
        onStarted={onStarted}
        onStopped={onStopped}
      />,
    );
  }

  return diceElements;
};

const toDiceInfo = (type: DiceType): DiceInfo => {
  switch (type) {
    case DiceType.d4:
      return D4;
    case DiceType.d6:
      return D6;
    case DiceType.d8:
      return D8;
    case DiceType.d10:
      return D10;
    case DiceType.d12:
      return D12;
    case DiceType.d20:
      return D20;
  }
};
