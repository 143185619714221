import { deserializeCharacter } from '../../api/urlCharacterSerializer';
import { CharacterThunks } from '../character/thunks';
import { AppDispatch, AppState, AppThunkAction, DiceStyle, MutableThunkState, RollSuggetion } from '../state';
import { SettingsAction } from './reducer';

const MAX_ROLL_HISTORY_ELEMENTS = 10;

const setShowAvatar =
  (showAvatar: boolean): AppThunkAction =>
  (dispatch: AppDispatch, _store, { settingsStorage }): void => {
    settingsStorage.setShowAvatar(showAvatar);
    dispatch(SettingsAction.setShowAvatar(showAvatar));
  };

const setOrthographicCameraEnabled =
  (enable: boolean): AppThunkAction =>
  (dispatch: AppDispatch, _store, { settingsStorage }): void => {
    settingsStorage.setEnableOrthographicCamera(enable);
    dispatch(SettingsAction.setOrthographicCameraEnabled(enable));
  };

const setAlterntaeArrow =
  (enable: boolean): AppThunkAction =>
  (dispatch: AppDispatch, _store, { settingsStorage }): void => {
    settingsStorage.setAlterntaeArrow(enable);
    dispatch(SettingsAction.setAlterntaeArrow(enable));
  };

const setGameTag =
  (tag?: string): AppThunkAction =>
  (dispatch: AppDispatch, _store, { settingsStorage }): void => {
    settingsStorage.setGameTag(tag);
    dispatch(SettingsAction.setGameTag(tag));
  };

const setDiceStyle =
  (diceStyle: DiceStyle): AppThunkAction =>
  (dispatch: AppDispatch, _store, { settingsStorage }): void => {
    settingsStorage.setDiceStyle(diceStyle);
    dispatch(SettingsAction.setDiceStyle(diceStyle));
  };

const importCharacter =
  (): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState): void => {
    const { characterToImport } = getState().settings;

    if (characterToImport) {
      dispatch(CharacterThunks.setCharacter(characterToImport.characterInfo));
      dispatch(CharacterThunks.setAbilities(characterToImport.abilities));

      dispatch(clearCharacterToImport());
    }
  };

const tryImportCharacter =
  (url: string): AppThunkAction =>
  (dispatch: AppDispatch): void => {
    try {
      const parsedUrl = new URL(url);
      const characterToImport = deserializeCharacter(parsedUrl.searchParams);

      if (characterToImport) {
        dispatch(SettingsAction.setCharacterToImport(characterToImport));
      }
    } catch (err) {
      console.error('Character Import Error:', err);
    }
  };

const clearCharacterToImport =
  (): AppThunkAction =>
  (dispatch: AppDispatch): void => {
    dispatch(SettingsAction.clearCharacterToImport());
    window.history.replaceState(null, '', window.origin);
  };

const addRollSuggestion =
  (suggestion: RollSuggetion): AppThunkAction =>
  (dispatch: AppDispatch, getState: () => AppState, { settingsStorage }: MutableThunkState): void => {
    const { settings } = getState();
    const { rollSuggestions = [] } = settings;

    const newSuggestions = rollSuggestions.filter((item) => JSON.stringify(item) !== JSON.stringify(suggestion)); //TODO: optimaize equality check
    newSuggestions.splice(MAX_ROLL_HISTORY_ELEMENTS - 1); //allow only MAX_ROLL_HISTORY_ELEMENTS suggestions
    newSuggestions.unshift(suggestion);

    settingsStorage.saveRollSuggestions(newSuggestions);
    dispatch(SettingsAction.setRollSuggestions(newSuggestions));
  };

export const SettingsThunk = {
  setShowAvatar,
  setOrthographicCameraEnabled,
  setAlterntaeArrow,
  setGameTag,
  importCharacter,
  tryImportCharacter,
  clearCharacterToImport,
  addRollSuggestion,
  setDiceStyle,
};
