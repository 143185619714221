import { DiceType } from '../../../api/DnD/dices';
import { DiceDescription, DiceInfo, DiceOptions } from '../diceTypes';
import { FACE_TEXTS } from '../r3fConstants';
import { createGeometry, createShape } from '../utils';

const createDescription = (): DiceDescription => {
  const t = (1 + Math.sqrt(5)) / 2;

  const desc: DiceDescription = {
    type: DiceType.d20,
    tab: -0.2,
    af: -Math.PI / 4 / 2,
    // chamfer: 0.955,
    chamfer: 1,
    vertices: [
      [-1, t, 0],
      [1, t, 0],
      [-1, -t, 0],
      [1, -t, 0],
      [0, -1, t],
      [0, 1, t],
      [0, -1, -t],
      [0, 1, -t],
      [t, 0, -1],
      [t, 0, 1],
      [-t, 0, -1],
      [-t, 0, 1],
    ],
    faces: [
      [0, 1, 7, 1],
      [2, 4, 11, 2],
      [8, 6, 7, 3],
      [4, 9, 5, 4],
      [0, 11, 5, 5],
      [3, 8, 9, 6],
      [0, 7, 10, 7],
      [3, 2, 6, 8],
      [9, 8, 1, 9],
      [6, 2, 10, 10],
      [1, 5, 9, 11],
      [11, 10, 2, 12],
      [0, 5, 1, 13],
      [3, 9, 4, 14],
      [0, 10, 11, 15],
      [3, 6, 8, 16],
      [10, 7, 6, 17],
      [5, 11, 4, 18],
      [7, 1, 8, 19],
      [3, 4, 2, 20],
    ],
    scaleFactor: 1,
    values: 20,
    faceTexts: FACE_TEXTS,
    textMargin: 1.0,
    mass: 400,
  };

  return desc;
};

const description = createDescription();

const options: DiceOptions = {
  size: 2.5,
  backColor: '#ed0000',
  fontColor: '#ffffff',
};

export const D20: DiceInfo = {
  description,
  options,
  geometry: createGeometry(options.size, description),
  args: createShape(description, options.size),
};
