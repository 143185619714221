import { ConvexPolyhedronArgs, Triplet } from '@react-three/cannon';
import * as THREE from 'three';
import { createFaceTexture } from './TextureCreator';
import { DiceDescription, DiceOptions } from './diceTypes';

export const createGeometry = (size: number, desc: DiceDescription): THREE.BufferGeometry => {
  const radius = size * desc.scaleFactor;
  const vectors: THREE.Vector3[] = desc.vertices.map((v) => new THREE.Vector3().fromArray(v).normalize());
  const chamferGeometry = getChamferGeometry(vectors, desc.faces, desc.chamfer);
  const geometry = makeGeometry(chamferGeometry.vectors, chamferGeometry.faces, radius, desc.tab, desc.af);

  return geometry;
};

const getChamferGeometry = (
  vectors: THREE.Vector3[],
  faces: number[][],
  chamfer: number,
): {
  vectors: THREE.Vector3[];
  faces: number[][];
} => {
  const chamfer_vectors: THREE.Vector3[] = [];
  const chamfer_faces: number[][] = [];
  const corner_faces: number[][] = new Array(vectors.length);

  for (let i = 0; i < vectors.length; ++i) corner_faces[i] = [];
  for (let i = 0; i < faces.length; ++i) {
    const ii = faces[i];
    const fl = ii.length - 1;
    const center_point = new THREE.Vector3();
    const face: number[] = new Array(fl);

    for (let j = 0; j < fl; ++j) {
      const vv = vectors[ii[j]].clone();
      center_point.add(vv);
      corner_faces[ii[j]].push((face[j] = chamfer_vectors.push(vv) - 1));
    }
    center_point.divideScalar(fl);
    for (let j = 0; j < fl; ++j) {
      const vv = chamfer_vectors[face[j]];
      vv.subVectors(vv, center_point).multiplyScalar(chamfer).addVectors(vv, center_point);
    }
    face.push(ii[fl]);
    chamfer_faces.push(face);
  }
  for (let i = 0; i < faces.length - 1; ++i) {
    for (let j = i + 1; j < faces.length; ++j) {
      const pairs = [];
      let lastm = -1;

      for (let m = 0; m < faces[i].length - 1; ++m) {
        const n = faces[j].indexOf(faces[i][m]);
        if (n >= 0 && n < faces[j].length - 1) {
          if (lastm >= 0 && m !== lastm + 1) pairs.unshift([i, m], [j, n]);
          else pairs.push([i, m], [j, n]);
          lastm = m;
        }
      }
      if (pairs.length !== 4) continue;
      chamfer_faces.push([
        chamfer_faces[pairs[0][0]][pairs[0][1]],
        chamfer_faces[pairs[1][0]][pairs[1][1]],
        chamfer_faces[pairs[3][0]][pairs[3][1]],
        chamfer_faces[pairs[2][0]][pairs[2][1]],
        -1,
      ]);
    }
  }

  for (let i = 0; i < corner_faces.length; ++i) {
    const cf = corner_faces[i];
    const face = [cf[0]];
    let count = cf.length - 1;

    while (count) {
      for (let m = faces.length; m < chamfer_faces.length; ++m) {
        let index = chamfer_faces[m].indexOf(face[face.length - 1]);
        if (index >= 0 && index < 4) {
          if (--index === -1) index = 3;
          const next_vertex = chamfer_faces[m][index];
          if (cf.indexOf(next_vertex) >= 0) {
            face.push(next_vertex);
            break;
          }
        }
      }
      --count;
    }
    face.push(-1);
    chamfer_faces.push(face);
  }
  return { vectors: chamfer_vectors, faces: chamfer_faces };
};

const makeGeometry = (vertices: THREE.Vector3[], faces: number[][], radius: number, tab: number, af: number) => {
  const geom = new THREE.BufferGeometry();

  for (let i = 0; i < vertices.length; ++i) {
    vertices[i] = vertices[i].multiplyScalar(radius);
  }

  const positions = [];
  const normals = [];
  const uvs = [];

  const cb = new THREE.Vector3();
  const ab = new THREE.Vector3();
  let materialIndex;
  let faceFirstVertexIndex = 0;

  for (let i = 0; i < faces.length; ++i) {
    const ii = faces[i];
    const fl = ii.length - 1;
    const aa = (Math.PI * 2) / fl;
    materialIndex = ii[fl] + 1;
    for (let j = 0; j < fl - 2; ++j) {
      //Vertices
      positions.push(...vertices[ii[0]].toArray());
      positions.push(...vertices[ii[j + 1]].toArray());
      positions.push(...vertices[ii[j + 2]].toArray());

      // Flat face normals
      cb.subVectors(vertices[ii[j + 2]], vertices[ii[j + 1]]);
      ab.subVectors(vertices[ii[0]], vertices[ii[j + 1]]);
      cb.cross(ab);
      cb.normalize();

      // Vertex Normals
      normals.push(...cb.toArray());
      normals.push(...cb.toArray());
      normals.push(...cb.toArray());

      //UVs
      uvs.push((Math.cos(af) + 1 + tab) / 2 / (1 + tab), (Math.sin(af) + 1 + tab) / 2 / (1 + tab));
      uvs.push(
        (Math.cos(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
        (Math.sin(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
      );
      uvs.push(
        (Math.cos(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
        (Math.sin(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
      );
    }

    //Set Group for face materials.
    const numOfVertices = (fl - 2) * 3;
    for (let i = 0; i < numOfVertices / 3; i++) {
      geom.addGroup(faceFirstVertexIndex, 3, materialIndex);
      faceFirstVertexIndex += 3;
    }
  }

  geom.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
  geom.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
  geom.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
  geom.boundingSphere = new THREE.Sphere(new THREE.Vector3(), radius);
  return geom;
};

export const createShape = ({ vertices, faces, scaleFactor }: DiceDescription, size: number): ConvexPolyhedronArgs => {
  const radius = size * scaleFactor;
  const vectors: THREE.Vector3[] = vertices.map((v) => new THREE.Vector3().fromArray(v).normalize());

  const cv: Triplet[] = new Array(vectors.length);
  const cf: number[][] = new Array(faces.length);

  for (let i = 0; i < vectors.length; ++i) {
    const v = vectors[i];
    cv[i] = [v.x * radius, v.y * radius, v.z * radius];
  }

  for (let i = 0; i < faces.length; ++i) {
    cf[i] = faces[i].slice(0, faces[i].length - 1);
  }

  return [cv, cf];
};

export const createDiceTextures = (desc: DiceDescription, options: DiceOptions): (THREE.Texture | null)[] => {
  return desc.faceTexts.map((faceText) => createFaceTexture(faceText, options, desc.textMargin));
};

export const randomizeVelocity = (vxvy: [number, number]): { velocity: Triplet; angularVelocity?: Triplet } => {
  const VELOCITY_MOD = 30;

  //NOTE: our scene is rotated; so, vertical controlled by Z axis!
  return {
    velocity: [vxvy[0] * VELOCITY_MOD, 0, vxvy[1] * VELOCITY_MOD],
    angularVelocity: [20 * Math.random() - 10, 20 * Math.random() - 10, 20 * Math.random() - 10],
  };
};

export const isSameTriplet = (first: Triplet, second: Triplet, tolerance: number) => {
  return first.every((item, index) => Math.abs(item - second[index]) < tolerance);
};

export const getDistance = ([x1, y1]: [x: number, y: number], [x2, y2]: [x: number, y: number]): number =>
  Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

export const getDistance3D = ([x1, y1, z1]: Triplet, [x2, y2, z2]: Triplet): number =>
  Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1) + (z2 - z1) * (z2 - z1));

export const getRotationAngle = ([x1, y1]: [x: number, y: number], [x2, y2]: [x: number, y: number]): number =>
  Math.atan2(y2 - y1, x2 - x1);
