import { DiceStyle } from './redux/state';

const Items = [
  'images/items/armor 2.png',
  'images/items/armor.png',
  'images/items/axe.png',
  'images/items/axe2.png',
  'images/items/axeDouble.png',
  'images/items/axeDouble2.png',
  'images/items/backpack.png',
  'images/items/Boots.png',
  'images/items/bow.png',
  'images/items/bow2.png',
  'images/items/coin.png',
  'images/items/dagger.png',
  'images/items/dagger2.png',
  'images/items/document.png',
  'images/items/envelope.png',
  'images/items/gem.png',
  'images/items/gemGreen.png',
  'images/items/gemRed.png',
  'images/items/hammer.png',
  'images/items/hammer2.png',
  'images/items/heart.png',
  'images/items/helmet.png',
  'images/items/helmet2.png',
  'images/items/leather.png',
  'images/items/leather2.png',
  'images/items/necklace.png',
  'images/items/potion.png',
  'images/items/potion2.png',
  'images/items/potion3.png',
  'images/items/potion4.png',
  'images/items/scroll.png',
  'images/items/shield_semi.png',
  'images/items/shield_wood.png',
  'images/items/shield.png',
  'images/items/shield2.png',
  'images/items/shieldSmall.png',
  'images/items/shieldSmall2.png',
  'images/items/star.png',
  'images/items/sword.png',
  'images/items/sword2.png',
  'images/items/tome.png',
  'images/items/tools.png',
  'images/items/wand.png',
  'images/items/wand2.png',
  'images/items/woodSword.png',
  'images/items/X.png',
];

export type DiceStyleInfo = {
  displayName: string;
  styleName: string | undefined;
};

export const DiceStyleInfos = new Map<DiceStyle, DiceStyleInfo>([
  [
    DiceStyle.Solid,
    {
      displayName: 'Solid',
      styleName: undefined,
    },
  ],
  [
    DiceStyle.Original,
    {
      displayName: 'Original',
      styleName: 'original',
    },
  ],
]);

export const BASIC_RULES_URL = 'https://www.dndbeyond.com/sources/basic-rules';
export const TWITTER_URL = 'https://twitter.com/D20Gate';
export const FEEDBACK_URL = 'https://pmfsurvey.com/g/5408';
export const ROUND_BUTTON_SIZE = 32;

export const Constants = {
  Items,
  DiceStyleInfos,
};
